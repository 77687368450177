<template>
  <div>
    <emulators-banner/>
    <div id="app">
      <div v-if="$store.account && $route.path != route('/login')">
        <div class="text-center">
          <b-button class="float-left mt-2 ml-2 burger-btn" @click="toggleSidebar" target="sidebar">
            <font-awesome-icon icon="bars" style="color:black" />
          </b-button>
          <div class="p-2" style="height:54px;">
            <img :srcset="$app.productLogoTitle + ' 2x'" />
            <b-badge v-if="$app.environment" variant="success">{{ $app.environment }}</b-badge>
          </div>
        </div>
        <b-sidebar id="sidebar" :visible="sidebarOpen" @hidden="sidebarOpen = false">
          <b-nav class="sidebar" vertical>
            <b-nav-item :to="route('/charts')"><font-awesome-icon icon="chart-line" /> Charts</b-nav-item>
            <b-nav-item :to="route('/list')"><font-awesome-icon icon="list" /> List</b-nav-item>
            <b-nav-item :to="route('/bookmarks')"><font-awesome-icon icon="bookmark" /> Bookmarks</b-nav-item>
            <b-nav-item :to="route('/list-all')"><font-awesome-icon icon="th-large" /> List All</b-nav-item>
            <b-nav-item :to="route('/trends')"><font-awesome-icon icon="chart-column" /> Trends Data</b-nav-item>
            <b-nav-item :to="route('/charts3')"><font-awesome-icon icon="line-chart" /> Trends Charts</b-nav-item>
            <b-nav-item class="fixed-bottom">
              <b-button @click="logout"><font-awesome-icon icon="sign-out" />Logout</b-button>
            </b-nav-item>
          </b-nav>
        </b-sidebar>

      </div>
      <router-view v-if="$store.account || $route.path == route('/login')"></router-view>
    </div>
    <my-footer />
  </div>
</template>
<script>
import { app, useEmulators } from "@/services/firebase";
import { getAuth } from "firebase/auth";
let auth = getAuth(app);
import { getLog } from "@/services/log";
let log = getLog("fnstats-router");

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOut, faList, faChartLine, faBookmark, faSearch, faTimes, faEye, faExternalLinkAlt, faChartColumn, faThLarge, faLineChart } from '@fortawesome/free-solid-svg-icons' 
library.add(faSignOut, faList, faChartLine, faBookmark, faSearch, faTimes, faEye, faExternalLinkAlt, faChartColumn, faThLarge, faLineChart);

export default {
  components: {
    MyFooter: () => import("@/components/Footer.vue"),
    EmulatorsBanner: () => import("@/components/emulatorsBanner.vue")
  },
  data() {
    return {
      useEmulators,
      sidebarOpen: false,
      root: "/fnstats",
      default: "/charts"
    };
  },
  mounted() {
    this.$store.setState(this);
    // account
    this.AuthStateChangedListener = auth.onAuthStateChanged(async (account) => {
      log.log("onAuthStateChanged", account);
      //account = "forced";
      if (!account) {
        if (this.$route.path != this.route("/login"))
          this.$router.push(this.route("/login"));
      }
      this.$store.setAccount(account);
      this.$forceUpdate();
      if (account && this.$route.path == this.route("")) {
        this.$router.push(this.route(this.default));
      }
    });
  },
  methods: {
    route(path) {
      return this.root + path;
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    logout() {
      // Perform logout logic here
      auth.signOut()
        .then(() => {
          log.log("User signed out");
        })
        .catch(error => {
          log.error("Error signing out:", error);
        });
    }
  }
};
</script>
<style scoped>
.burger-btn {
  /* Add burger button styles here */
  background-color: white;
}

.sidebar {
  font-size: 20pt;
}

.sidebar>b-nav-item>a:hover {
  background-color: #b5b6b6;
}
</style>
  
  
  